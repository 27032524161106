import React from "react";

import "../../styles/components/footer.scss";

const Footer = props => {
  return (
    <div
      className={`section footer lsblue white ${
        props.className ? props.className : ""
      }`}
      id="footer"
    >
      {props.pdf && props.pdf === true ? (
        <div className="pdf content center">
          This report is intended for use by affiliated partners. The above
          results are provided for informational purposes only and not meant for
          distribution.
        </div>
      ) : null}
      <div className="copy content center">
        Copyright&nbsp;
        <span className="far fa-copyright" />
        &nbsp;{new Date().getFullYear()}
        &nbsp;LifeScore Labs, LLC 10 Fan Pier Blvd, Boston, MA 02210. All rights
        reserved.
      </div>
      {props.pdf && props.pdf === true ? null : (
        <div className="crn content center">
          <a className="blue" href="https://www.lifescorelabs.com/term-of-use">
            Terms of Use
          </a>
          &nbsp;&nbsp;{"|"}&nbsp;&nbsp;
          <a
            className="blue"
            href="https://www.lifescorelabs.com/privacy-policy"
          >
            Privacy Policy
          </a>
          &nbsp;&nbsp;MM202704-308913
        </div>
      )}
    </div>
  );
};

export default Footer;
