"use strict";

import axios from "axios";
const HOST = process.env.GATSBY_WEB_API_GENERAL;

export default {
  paused: false,
  verify: function(refresh = false) {
    if (this.paused === false) {
      return axios({
        url: `${process.env.GATSBY_WEB_API_VISUALIZATION}/verify${
          refresh === true ? "?refresh" : ""
        }`,
        method: "post",
        headers: {
          "x-ls360-accesstoken": this.getToken()
        }
      })
        .then(() => true)
        .catch(() => false);
    } else {
      return new Promise(resolve => resolve(true));
    }
  },
  pauseVerify: function() {
    this.paused = true;
  },
  resumeVerify: function() {
    this.paused = false;
  },
  login: function(data) {
    return axios
      .post(`${HOST}/login`, data)
      .then(response => {
        if (typeof window !== "undefined") {
          window.localStorage.removeItem("x-ls360-logout");
          window.localStorage.removeItem("x-ls360-user-permissions");
          this.setToken(response.headers["x-ls360-accesstoken"]);
          window.localStorage.setItem("x-ls360-email", response.data.email);
        }
      })
      .catch(e => {
        if (e.response.data && e.response.data.message) {
          return e.response.data.message;
        }
        return "Unknown error occurred. Please try again!";
      });
  },
  logout: function() {
    if (typeof window !== "undefined") {
      const token = this.getToken();
      window.localStorage.removeItem("x-ls360-accesstoken");
      window.localStorage.removeItem("x-ls360-email");
      window.localStorage.removeItem("x-ls360-user-permissions");
      if (!window.localStorage.getItem("x-ls360-logout")) {
        window.localStorage.setItem("x-ls360-logout", String(new Date()));
      }

      return axios({
        url: `${HOST}/logout`,
        method: "post",
        headers: {
          "x-ls360-accesstoken": token
        }
      })
        .then(() => false)
        .catch(() => true);
    }
  },
  resetPassword: function(data) {
    return axios({
      url: `${HOST}/reset`,
      method: "post",
      data
    })
      .then(() => null)
      .catch(e => {
        if (e.response && e.response.data && e.response.data.message) {
          return e.response.data.message;
        }

        return "Unknown error occurred. Please try again!";
      });
  },
  getToken: () => {
    return typeof window !== "undefined"
      ? window.localStorage.getItem("x-ls360-accesstoken")
      : null;
  },
  setToken: token => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("x-ls360-accesstoken", token);
    }
  },
  getUserPermissions: () => {
    return typeof window !== "undefined"
      ? window.localStorage.getItem("x-ls360-user-permissions")
      : null;
  },
  setUserPermissions: userPermissions => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("x-ls360-user-permissions", userPermissions);
    }
  }
};
