exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-batch-process-js": () => import("./../../../src/pages/batch-process.js" /* webpackChunkName: "component---src-pages-batch-process-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-file-upload-js": () => import("./../../../src/pages/file-upload.js" /* webpackChunkName: "component---src-pages-file-upload-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-med-360-js": () => import("./../../../src/pages/med360.js" /* webpackChunkName: "component---src-pages-med-360-js" */),
  "component---src-pages-mls-360-js": () => import("./../../../src/pages/mls360.js" /* webpackChunkName: "component---src-pages-mls-360-js" */),
  "component---src-pages-mylifescore-360-js": () => import("./../../../src/pages/mylifescore360.js" /* webpackChunkName: "component---src-pages-mylifescore-360-js" */),
  "component---src-pages-output-js": () => import("./../../../src/pages/output.js" /* webpackChunkName: "component---src-pages-output-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-visualization-js": () => import("./../../../src/pages/visualization.js" /* webpackChunkName: "component---src-pages-visualization-js" */)
}

