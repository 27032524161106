import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import withRouter from "./withRouter";
import { INITIALIZE, USER_LOGGED_IN, USER_LOGGED_OUT } from "../state/actions";

import auth from "./auth";
import { authenticatedPages, unauthenticatedPages } from "./pageAuthentication";

const APP_ROUTES = _.map(authenticatedPages, value => value);

const AuthWrapper = ({ initialized, loggedIn, location, initialize, logIn, logout, children }) => {
  const intervalRef = useRef(null);

  useEffect(() => {
    const checkLoggedOutHandler = event => {
      if (event.key === "x-ls360-logout" || event.key === "x-ls360-user-permissions") {
        window.localStorage.removeItem(event.key);
      }

      const checkLoggedOut =
        window.localStorage.getItem("x-ls360-logout") !== null ||
        window.localStorage.getItem("x-ls360-accesstoken") === null ||
        window.localStorage.getItem("x-ls360-email") === null ||
        window.localStorage.getItem("x-ls360-user-permissions") === null;

      if (checkLoggedOut && !unauthenticatedPages.includes(window.location.pathname)) {
        window.location.reload();
      }
    };

    if (!initialized) {
      if (userInApp()) {
        verify(true).then(() => initialize());
      } else {
        initialize();
      }

      window.addEventListener("storage", checkLoggedOutHandler);

      return () => {
        window.removeEventListener("storage", checkLoggedOutHandler);
      };
    }
  }, [initialized, initialize]);

  useEffect(() => {
    if (loggedIn && location.pathname !== location.pathname) {
      clearMyInterval(intervalRef.current);
      verify(true).then(() => {
        setMyInterval();
      });
    }
  }, [loggedIn, location]);

  useEffect(() => {
    if (loggedIn) {
      setMyInterval();
    }
  }, [loggedIn]);

  const userInApp = () => {
    const pathname = location.pathname;

    return _.some(APP_ROUTES, route => {
      const pattern = `(^${route})/?`;
      const re = new RegExp(pattern, "g");
      const matches = pathname.match(re);

      if (matches == null) {
        return false;
      }

      return matches[0] === pathname;
    });
  };

  const verify = (refresh = false) => {
    return auth.verify(refresh).then(valid => {
      if (!valid) {
        return auth.logout().then(() => {
          logout(userInApp(), initialized);
        });
      }

      logIn();

      if (!intervalRef.current) {
        setMyInterval();
      }
    });
  };

  const clearMyInterval = () => {
    clearInterval(intervalRef.current);
  };

  const setMyInterval = () => {
    clearMyInterval();
    intervalRef.current = setInterval(() => {
      if (loggedIn) {
        verify();
      } else {
        clearMyInterval();
      }
    }, 60000);
  };

  //TODO: Do not render children if the user is not logged in and is trying to access an authenticated page

  return <div>{children}</div>;
};

const mapStateToProps = state => ({
  appState: state.appState,
  initialized: state.appState.initialized,
  loggedIn: state.appState.loggedIn
});

const mapDispatchToProps = dispatch => ({
  initialize: () => {
    dispatch({ type: INITIALIZE });
  },
  logIn: () => {
    dispatch({ type: USER_LOGGED_IN });
  },
  logout: (userInApp = true, initialized = true) => {
    dispatch({ type: USER_LOGGED_OUT });
    if (userInApp === true) {
      const pathname =
        initialized === true ? "/login#sessionTimeout" : "/login";
      navigate(pathname);
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthWrapper));