import {
  ADMIN_PAGE,
  DEMO_PAGE,
  BATCH_PROCESS_PAGE,
  DEVELOPERS_PAGE,
  FILE_UPLOAD_PAGE,
  HOME_PAGE,
  VISUALIZATION_PAGE
} from "./constants";

export const unauthenticatedPages = [
  "/login",
  "/mls360",
  "/mylifescore360",
  "/output",
  "/med360"
];

export const authenticatedPages = [
  HOME_PAGE,
  VISUALIZATION_PAGE,
  DEVELOPERS_PAGE,
  FILE_UPLOAD_PAGE,
  BATCH_PROCESS_PAGE,
  ADMIN_PAGE,
  DEMO_PAGE
];
