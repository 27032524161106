"use strict";

// actions
export const HOME_PAGE = "/home";
export const VISUALIZATION_PAGE = "/visualization";
export const DEVELOPERS_PAGE = "/developers";
export const FILE_UPLOAD_PAGE = "/file-upload";
export const BATCH_PROCESS_PAGE = "/batch-process";
export const ADMIN_PAGE = "/admin";
export const DEMO_PAGE = "/demo";

// product names
export const MED360 = "Med360";
export const FLUIDLESS = "Fluidless";
export const GOBI = "Gobi";
export const EHD360 = "Ehd360";
export const EHD360CUSTOM1 = "Ehd360 Custom1";
export const EHD360CUSTOM2 = "Ehd360 Custom2";

export const PRODUCT_NAMES = {
  med360: MED360,
  fluidless: FLUIDLESS,
  gobi: GOBI,
  ehd360: EHD360,
  ehd360custom1: EHD360CUSTOM1,
  ehd360custom2: EHD360CUSTOM2
};

export const API_TYPES = {
  single: "Single API",
  batch: "Batch API"
};

export const DATE_TYPES = {
  day: "Daily",
  week: "Weekly",
  month: "Monthly",
  quarter: "Quarterly",
  year: "Yearly"
};

export const MONTHS = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec"
};

export const FILE_TYPES = [
  "pdf",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "pps",
  "ppsx",
  "json",
  "txt",
  "odt",
  "csv",
  "xls",
  "xlsx",
  "xml"
];
