import React from "react";
import "regenerator-runtime/runtime";
import { navigate } from "gatsby";
import { Provider } from "react-redux";
import { Router as ReactRouter } from "react-router-dom";

import { SET_HASH_VALUE } from "./src/state/actions";
import createStore from "./src/state/store";
import { createBrowserHistory } from "history";

import Helmet from "react-helmet";
import favicon from "./src/assets/favicon.png";
import Footer from "./src/components/Footer";
import AuthWrapper from "./src/utils/authWrapper";

import "./src/styles/main.scss";
import "./src/assets/fontawesome-all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Replaces default renderer with the Redux Provider wrapper for state management
let forcedTransition = false;

// TODO: fix this!
export const wrapRootElement = ({ element }) => {
  const store = createStore();
  const history = typeof window !== "undefined" ? createBrowserHistory() : null;

  history.listen(location => {
console.log("gatsby-browser location: "+JSON.stringify(location.location));
    let locationDetails = location.location;
    if (forcedTransition !== true) {
console.log(locationDetails.hash !== "");
      console.log(locationDetails.hash.split("#"));
      store.dispatch({
        type: SET_HASH_VALUE,
        payload:
          locationDetails.hash !== "" ? locationDetails.hash.split("#")[1] : locationDetails.hash
      });
    }

    forcedTransition = false;
    if (locationDetails.hash !== "") {
      forcedTransition = true;
      navigate(locationDetails.pathname);
    }
  });

  return (
    <Provider store={store}>
      <ReactRouter location={history.location} history={history}> {/* Replace with LocationProvider by reach router and pass in history, location not needed*/}
        <AuthWrapper>
          <Helmet>
            <link rel="icon" href={favicon} type="image/x-icon" />
            <link rel="shortcut icon" href={favicon} type="image/x-icon" />
            <meta charSet="UTF-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
          </Helmet>
          <div className="site">
            <div className="site-content lsgray">{element}</div>
            <Footer />
          </div>
        </AuthWrapper>
      </ReactRouter>
    </Provider>
  );
};

export default createBrowserHistory();