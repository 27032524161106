import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// TODO: replace withRouter hook with actual way to use react-router v5
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter;