import {
  INITIALIZE,
  SET_HASH_VALUE,
  USER_LOGGED_IN,
  USER_LOGGED_OUT
} from "../actions";

export default function(state = { initialized: false }, action) {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        initialized: true
      };
    case USER_LOGGED_IN:
      return {
        ...state,
        loggedIn: true
      };
    case USER_LOGGED_OUT:
      return {
        ...state,
        loggedIn: false
      };
    case SET_HASH_VALUE:
      return {
        ...state,
        hash: action.payload
      };
    default:
      return state;
  }
}
